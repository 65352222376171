<template>
  <v-card>
    <v-card-title
      class="error--text text--darken-1 headline error--text text-uppercase font-weight-bold text--darken-1"
    >
      <v-icon class="mr-2 mt-n1" color="error"
        >mdi-account-group</v-icon
      >
      Contact Groups
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-card-title>
    <p class="grey--text ml-8 mt-n5">List of all contact groups</p>
    <v-divider></v-divider>
    <v-row class="pa-4" justify="space-between">
      <v-col cols="5">
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2 text-capitalize"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-plus-thick</v-icon> New Group
            </v-btn>
            <v-divider></v-divider>
          </template>
          <v-card>
            <v-form ref="form" v-model="valid">
              <v-card-title>
                <span class="error--text text--darken-1">{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" class="my-n2">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Group Title"
                            :rules="nameRules"
                            color="grey"
                            dense
                            outlined
                            class="mb-n3"
                          >
                            <template v-slot:label>
                              <span class="input__label"
                                >Group Title
                                <v-icon small color="error" class="mt-n2"
                                  >mdi-star-outline</v-icon
                                ></span
                              >
                            </template>
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" class="my-n5">
                          <v-textarea
                            v-model="editedItem.description"
                            outlined
                            name="input-7-4"
                            label="Group Description"
                            color="grey"
                            dense
                            :rules="nameRules"
                            class="mb-n3"
                          >
                            <template v-slot:label>
                              <span class="input__label"
                                >Group Description
                                <v-icon small color="error" class="mt-n2"
                                  >mdi-star-outline</v-icon
                                ></span
                              >
                            </template>
                          </v-textarea>
                        </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  class="text-capitalize mx-1"
                  dark
                  @click="close"
                >
                  Cancel <v-icon small>mdi-cancel</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  dark
                  class="text-capitalize mx-1"
                  :loading="loading"
                  @click="save"
                >
                  Submit <v-icon>mdi-content-save-outline</v-icon>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <v-treeview
          :active.sync="active"
          :items="items"
          :open.sync="open"
          activatable
          color="primary"
          transition
          open-all
        >
          <template v-slot:prepend="{ item }">
            <v-icon v-if="!item.children"> mdi-account-group </v-icon>
          </template>

          <template v-slot:append="{ item }">
            <v-btn
              color="grey"
              class="mx-1 my-3"
              fab
              x-small
              dark
              @click="editItem(item)"
              v-if="!item.children"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              color="error"
              class="mx-1 my-3"
              fab
              x-small
              dark
              @click="deleteItem(item)"
              v-if="!item.children"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-treeview>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col class="d-flex text-center">
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="!selected"
            class="title grey--text text--lighten-1 font-weight-light"
            style="align-self: center"
          >
            Select a Group
          </div>
          <v-card
            v-else
            :key="selected.id"
            class="pt-6 mx-auto"
            flat
            max-width="400"
          >
            <v-card-text>
              <h3 class="headline mb-2">
                {{ selected.name }}
              </h3>
              <div class="primary--text mb-2">
                {{ selected.description }}
              </div>
            </v-card-text>
            <v-divider></v-divider>

            <v-row justify="center" tag="v-card-text">
              <v-treeview
                item-disabled="locked"
                :items="contacts"
                item-text="name"
                item-key="name"
              >
                <template v-slot:label="{ item }">
                    <div class="text-center">
                        <v-chip
                        class="ma-2"
                        color="success"
                        outlined
                        >
                            <v-avatar left>
                                <v-icon>mdi-account-circle</v-icon>
                            </v-avatar>
                            {{ item.name }}
                        </v-chip>
                    </div>
                </template>
              </v-treeview>
            </v-row>

          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import NotificationGroupsService from "../../services/notification_groups.service";
import UserService from "../../services/user.service";

export default {
  data: () => ({
    active: [],
    loading: false,
    abilities: [],
    contacts: [],
    open: [],
    groups: [],
    // users end
    valid: true,
    passwordRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    nameRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    emailRules: [
      (v) => !!v || "Valid E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    selectRules: [(v) => !!v || "Select is required"],
    dialog: false,
    headers: [
      {
        text: "Group Title",
        value: "name",
      },
      {
        text: "Group Description",
        value: "description",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false
      },
    ],
    editedIndex: -1,
    editedItem: {
        name: "",
        description: ""
    },
    defaultItem: {
        name: "",
        description: ""
    },
    remember: 0,
    timeout: 2000
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Group" : "Edit Group";
    },
    items() {
      return [
        {
          name: "Contact Groups",
          children: this.groups,
        },
      ];
    },
    selected() {
      if (!this.active.length) return undefined;
      const id = this.active[0];
      this.getAllContacts(id);
      return this.groups.find((group) => group.id === id);
    },
    ability_ids: {
      get() {
        if (!this.active.length) return undefined;
        const id = this.active[0];
        return this.roles.find((role) => role.id === id).ability_ids;
      },
      set() {},
    },
    checked(id, array) {
      if (!array.length) return false;
      return array.find((item) => item.id === id);
    },
    selected_role() {
      if (!this.active.length) return undefined;
      const id = this.active[0];
      let role = this.roles.find((role) => role.id === id);
      return role.abilities;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.getAllGroups();
    this.getAllRoles();
    this.getAllContacts();
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },

    reset() {
      this.$refs.form.reset();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },

    getAllRoles() {
      return UserService.getAllRoles().then(
        (response) => {
          if (response.status == 200) {
            this.roles = response.data.data.roles;
            this.abilities = response.data.data.abilities;
          } else {
            this.roles = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.roles = [];
          console.log(error);
        }
      );
    },

    getAllContacts(group_id) {
        return NotificationGroupsService.getGroupContacts(group_id).then(
        (response) => {
          if (response.status == 200) {
            this.contacts = response.data.data;
          } else {
            this.contacts = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.contacts = [];
          console.log(error);
        }
      );
    },

    getAllGroups() {
        return NotificationGroupsService.index().then(
        (response) => {
          if (response.status==200) {
            this.groups = response.data.data;
          } else {
            this.groups = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.groups = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    editItem(item) {
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
    },

    deleteItem(item) {
        const index = this.groups.indexOf(item);
        var deleteUser = confirm("Are you sure you want to delete this group?");
        if (deleteUser && this.groups.splice(index, 1)) {
            NotificationGroupsService.delete(item).then(
            (response) => {
                if (response.status==200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.close();
                } else {
                this.$store.dispatch("alert/error", response.data.message);
                }
            },
            (error) => {
                if (error.response.status == 422) {
                this.$store.dispatch("alert/error", error.response.data.message);
                }
                if (error.response.status == 401) {
                this.handleLogout();
                }
                console.log(error);
                this.$store.dispatch("alert/error", error.response.data.message);
            }
            );
        }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        // edit
        if (this.editedIndex > -1) {
          Object.assign(this.groups[this.editedIndex], this.editedItem);
          NotificationGroupsService.update(this.editedItem).then(
            (response) => {
              if (response.status==200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.index();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.index();
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
                this.index();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.index();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          // create
          NotificationGroupsService.store(this.editedItem).then(
            (response) => {
              if (response.status==200) {
                this.groups.push(this.editedItem);
                this.$store.dispatch(
                  "alert/success",
                  "Item created successfully"
                );
                this.loading = false;
                this.index();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.index();
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
                this.index();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.index();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      } else {
        this.loading = false;
        this.index();
      }
    },
  },
};
</script>
