// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class NotificationGroupsService {

    index() {
        return axios.get(API_URL + 'notification-groups', { headers: authHeader() })
    }
    show(id) {
        return axios.get(API_URL + 'notification-groups/'+id, { headers: authHeader() })
    }
    store(user) {
        return axios.post(API_URL + 'notification-groups', user, { headers: authHeader() })
    }
    update(user) {
        return axios.put(API_URL + 'notification-groups/'+user.id, user, { headers: authHeader() })
    }
    delete(user) {
        return axios.delete(API_URL + 'notification-groups/'+user.id, { headers: authHeader() })
    }

    getGroupContacts(group) {
        return axios.get(API_URL + 'contacts/group/' + group, { headers: authHeader() })
    }

    getContacts() {
        return axios.get(API_URL + 'contacts', { headers: authHeader() })
    }
}

export default new NotificationGroupsService()
